<template>
  <div>
    <div class="main-title">排课教师管理</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            <li>录入教师后才可进行排课</li>
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select v-model="searchType" clearable slot="prepend" placeholder="选择类型" size="mini">
            <el-option label="微信昵称" :value="1"></el-option>
            <el-option label="UID" :value="2"></el-option>
          </el-select>
          <el-input class="margin-l-sm" v-model="userName" size="mini" style="width: 160px" clearable>
          </el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
        </span>

        <span class="margin-l-sm fl-r">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="$router.push('/addSchedulingTeacher')">新增教师</el-button>
        </span>

        <span class="fl-r"> </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content" v-loading="loading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="id"> </el-table-column>
          <el-table-column prop="name" label="姓名">
            <template slot-scope="scope">
              <div class="tag">
                <div class="tag-bg" :style="{'background':scope.row.color,'border':'1px solid '+scope.row.color}"></div>
                <span class="tag-name">
                {{scope.row.name}}
              </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="uid" label="uid"> </el-table-column>

          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.status == 0">离职</el-tag>
              <el-tag v-else>在职</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="教师主管">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_manage == 1" >管理员</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="created_at" label="创建时间">
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-link slot="reference" type="primary" @click="$router.push('/editSchedulingTeacher/'+scope.row.id)">编辑</el-link>

<!--               <span v-if="!scope.row.deleted_at">-->
<!--                  <el-popconfirm-->
<!--                      icon="el-icon-info"-->
<!--                      icon-color="red"-->
<!--                      title="会员关闭不可恢复,确定关闭吗？"-->
<!--                      @confirm="delTeacher(scope.row.id)"-->
<!--                  >-->
<!--                  <el-link slot="reference" type="primary">删除</el-link>-->
<!--                  </el-popconfirm>-->

               </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                         layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage"
                         :total="total">
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "list",
  data() {
    return {
      searchData: {
        module: "",
        status: "",
      },
      loading: false,
      tableData: [],
      searchType: null,
      userName: null,
      value2: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    ...mapActions("courseScheduling", ["getCourseSchedulingTeacherList"]),
    search() {
      this.searchData.datePick = this.value2;
      if (this.searchType == 1) this.searchData.nickName = this.userName;
      else this.searchData.userId = this.userName ?? 0;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { data } = await this.getCourseSchedulingTeacherList(form);
      this.total = data.total;
      this.tableData = data.list;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async closeUserVip(id){
      const { ret } = await this.closeVip(id);
      if(ret === 0) {
        this.$message.success("关闭成功");
        this.getList();
      }else{
        this.$message.error("关闭失败");
      }
    }
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.el-popover {
  min-width: 80px;
}
.tag{
  position: relative;
  padding: 5px;
  display: inline-block;
}
.tag-bg{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  border-radius: 5px;
  opacity: .8;
}
.tag-name{
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 12px;
}
</style>
